export default {
  name: "projectsCarousel",
  props: {
    projects: Object
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    // this.$store.dispatch('project/getProject', this.projectSlug);
    console.log("---> " + this.projects);
  }
};
