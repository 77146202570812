import { SEOResource } from "../app-seo.js";
import ProjectsCarousel from "./_components/projectsCarousel/projectsCarousel.vue";

export default {
  name: "project",
  components: {
    ProjectsCarousel
  },
  data() {
    return {
      projectSlug: this.$route.params.projectSlug
    };
  },
  computed: {
    items: {
      get: function() {
        return this.$store.getters["project/items"];
      },
      set: function() {}
    },
    loading: {
      get: function() {
        if (this.$store.getters["project/loadingStatus"] === "ERROR")
          this.$router.replace({
            path: "/" + this.$route.params.lang + "/404"
          });

        return this.$store.getters["project/loadingStatus"] === "LOADING"
          ? true
          : false;
      },
      set: function() {}
    }
  },
  mounted() {
    this.$store.dispatch("project/seo");
    this.$store.dispatch("project/getProject", this.projectSlug);
  },
  metaInfo() {
    return new SEOResource().SEOData(this);
  }
};
